import React from 'react';
import { FourOhFour } from 'gatsby-theme-carbon';

const links = [
  { href: '/', text: 'Prva' },
  { href: '/kontakt', text: 'Kontakt' },
  { href: '/galerija', text: 'Galerija' },
];

const Custom404 = () => <FourOhFour links={links} />;

export default Custom404;
